<script setup>
import { createPostJsonLd } from '@@/utils/PageUtils';
import { getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import { parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useDailyReadsStore } from '@@/stores/DailyReads';
import { useNewsStore } from '@@/stores/News';
import { useUserStore } from '@@/stores/User';

const { fullPath, params } = useRoute();

// 3 URLs get us to this page:
// /dailysnow/:slug
// /dailysnow/:slug/post/:postId
// /dailysnow/post/:postId => redirects to /dailysnow/:slug/post/:postId
const {
  postId = null,
  slug = null,
} = params;

const dailyReadsStore = useDailyReadsStore();
const newsStore = useNewsStore();
const userStore = useUserStore();

// If slug was missing, we want to call the fetchDailyRead endpoint w/o content
// and redirect to the slug version of the URL
if (!slug) {
  const { article } = await dailyReadsStore.fetchDailyRead({
    postId,
    slug: null,
    withContent: false,
  });

  const redirectUrl = `/dailysnow/${article.slug}/post/${postId}`;
  // permanently redirect to URL with slug
  navigateTo(redirectUrl, { redirectCode: 301 });
}

const { inAppView } = useInAppView();

// Fetch Content
//
// - Always get content with the daily read post
// - We use the presence or absence of content to determine if the user is all access
// - Content endpoint also indicates to metrics that the user was viewing a daily snow post

const { data, error } = await useAsyncData(async () => {
  // NOTE: Use Promise.all() instead of multiple await calls since useAsyncData() doesn't seem to
  // wait for multiple await calls, only the first; but it will wait for a single Promise.all().
  // So Promise.all() is used to create a single promise that useAsyncData() will properly wait
  // for.

  const promises = [];

  promises.push(
    dailyReadsStore.fetchDailyRead({
      postId,
      slug,
      withContent: true,
    }),
  );

  if (!inAppView.value) {
    promises.push(
      newsStore.fetchArticles({
        skip: 0,
        limit: 3,
        saveToStore: false,
      }),
    );
  }

  const [articleResponse, newsResponse] = await Promise.all(promises);

  // newsResponse doesn't come back when inAppView so need a default
  return {
    article: articleResponse.article,
    recentNewsArticles: newsResponse?.news ?? [],
  };
});

if (error.value) {
  throw createError(parseOpenMountainApiError(error.value));
}

// Setup post specific SEO meta data

const { article, recentNewsArticles } = data.value;
const { image_url } = article.post;

const jsonLd = createPostJsonLd({
  post: article.post,
  isFree: false,
});

useSeoMeta({
  ogImage: image_url,
  twitterImage: image_url,
});

useHead({
  script: [{
    children: JSON.stringify(jsonLd, null, 2),
    id: 'jsonld',
    type: 'application/ld+json',
  }],
});

// Setup non-reactive properties used in template

const { isAllAccess, isGuest } = userStore;
const containerClass = inAppView.value
  ? 'tw-p-2.5'
  : 'lg:tw-w-2/3 lg:tw-mr-4 tw-mb-4 lg:tw-mb-0';

const pageParams = article ? { article } : null;
const previousPostUrl = `/dailysnow/${article.slug}/post/${article.post.prev_id}`;
const nextPostUrl = `/dailysnow/${article.slug}/post/${article.post.next_id}`;
const nextPostCtaUrl = getStartFreeTrialUrl({
  isGuest,
  query: {
    source: 'dailysnow_nextpost',
    return_to: fullPath,
  },
});
</script>

<template>
  <PageContent
    :can-set-meta-image="false"
    class="tw-overflow-hidden"
    :can-show-select-favorite-list-dialog="true"
    :full-screen="inAppView"
    :page-params="pageParams"
  >
    <div
      v-if="!inAppView"
      class="tw-my-3 lg:tw-mt-6 lg:tw-mb-7"
    >
      <h3 class="page-title heading-color">
        {{ article.name }} Daily Snow
      </h3>
    </div>

    <div class="lg:tw-flex">
      <!-- left col main content -->
      <div
        :class="containerClass"
      >
        <DailySnowPost
          class="tw-mb-8"
          :is-guest="isGuest"
          :daily-read="article"
        />
        <div
          :class="['tw-flex tw-flex-col lg:tw-flex-row', inAppView ? 'tw-mb-16' : 'tw-mb-8']"
        >
          <PostNavigationButton
            v-if="!inAppView && article.post.prev_id"
            class="tw-w-full lg:tw-w-1/2 tw-mb-4 lg:tw-mr-4"
            direction="previous"
            :display-block="true"
            :to="previousPostUrl"
          >
            Previous Daily Snow
          </PostNavigationButton>
          <PostNavigationButton
            v-if="!inAppView && article.post.next_id"
            class="tw-w-full lg:tw-w-1/2 lg:tw-ml-2"
            direction="next"
            :display-block="true"
            :to="nextPostUrl"
          >
            Next Daily Snow
          </PostNavigationButton>
          <PostNavigationButton
            v-if="!inAppView && !article.post.next_id && !isAllAccess"
            class="tw-w-full lg:tw-w-1/2 lg:tw-ml-2"
            direction="next"
            :display-block="true"
            :is-action="true"
            :to="nextPostCtaUrl"
          >
            <div>
              <div>No need to refresh!</div>
              <div class="tw-font-light tw-text-sm">
                As an All-Access subscriber, receive the Daily Snow by email.
              </div>
            </div>
          </PostNavigationButton>
        </div>
        <PostAuthor
          v-if="!inAppView"
          class="tw-mb-8"
          :post="article.post"
          section-title="About Our Forecaster"
        />
      </div>

      <!-- right col main content -->
      <div
        v-if="!inAppView"
        class="lg:tw-w-1/3 lg:tw-ml-4"
      >
        <h3 class="tw-mb-3 section-title heading-color">
          Free OpenSnow App
        </h3>
        <DownloadAppButtons
          container-class="tw-mb-8"
        />
        <CompareTopSnow
          title-prefix="Nearby"
          compare-type="daily-reads"
          :compare-id="article.slug"
          :limit="20"
        />
      </div>
    </div>

    <div
      v-if="!inAppView"
      class="tw-my-8"
    >
      <PostLinkList
        :posts="recentNewsArticles"
      />
    </div>
  </PageContent>
</template>
