<template>
  <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mb-4">
    <Button
      additional-class-names="tw-w-full lg:tw-w-1/2 lg:tw-mr-1 tw-mb-2 lg:tw-mb-0"
      :type="buttonTypes.chip"
      :to="favoriteButton.to"
      @click="handleFavoriteButtonClick"
    >
      {{ favoriteButton.text }}
      <font-awesome-icon
        class="tw-ml-2"
        prefix="far"
        :icon="favoriteButton.icon"
      />
    </Button>

    <Button
      additional-class-names="tw-w-full lg:tw-w-1/2 lg:tw-ml-1"
      :type="buttonTypes.chip"
      :to="subscribeButton.to"
      @click="handleSubscribeButtonClick"
    >
      {{ subscribeButton.text }}
      <font-awesome-icon
        class="tw-ml-2"
        prefix="far"
        :icon="subscribeButton.icon"
      />
    </Button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';
import { useUserNotificationsStore } from '@@/stores/UserNotifications';
import { useUserStore } from '@@/stores/User';

export default {
  name: 'PostActions',

  props: {
    dailyRead: {
      type: Object,
      required: true,
    },
  },

  async setup() {
    const userNotificationsStore = useUserNotificationsStore();
    const userStore = useUserStore();

    if (userStore.isGuest) {
      await useAsyncData(() => userNotificationsStore.fetchDailyReads());
    }
  },

  computed: {
    ...mapState(useUserStore, ['isGuest', 'isAllAccess']),
    ...mapState(useUserFavoritesStore, [
      'isFavoriteDailyRead',
      'getFavoriteListsForDailyRead',
    ]),
    ...mapState(useUserNotificationsStore, [
      'isSubscribedToDailyRead',
    ]),

    buttonTypes() {
      return ButtonTypes;
    },

    favoriteButton() {
      const isFavorite = !this.isGuest
        && this.isFavoriteDailyRead({ dailyReadId: this.dailyRead.id });

      const toUrl = getStartFreeTrialUrl({
        isGuest: true,
        query: {
          source: 'dailysnow_add_fav',
          return_to: this.$route.fullPath,
        },
      });

      return {
        icon: isFavorite ? 'star' : ['far', 'star'],
        text: isFavorite ? 'Favorited' : 'Add to Favorites',
        to: this.isGuest ? toUrl : null,
      };
    },

    subscribeButton() {
      const isSubscribed = !this.isGuest
        && this.isSubscribedToDailyRead({ dailyReadId: this.dailyRead.id });

      let toUrl = null;

      if (!this.isAllAccess) {
        toUrl = getStartFreeTrialUrl({
          isGuest: this.isGuest,
          query: {
            source: 'dailysnow_add_alert',
            return_to: this.$route.fullPath,
          },
        });
      }

      return {
        icon: isSubscribed ? 'bell' : ['far', 'bell'],
        text: isSubscribed ? 'Subscribed' : 'Subscribe by Email',
        to: !this.isAllAccess ? toUrl : null,
      };
    },
  },

  methods: {
    ...mapActions(useUserFavoritesStore, [
      'showSelectFavoriteListDialog',
    ]),
    ...mapActions(useUserNotificationsStore, [
      'fetchDailyReads',
      'subscribeToDailyRead',
      'unsubscribeToDailyRead',
    ]),

    async handleFavoriteButtonClick($event) {
      if (this.isGuest) {
        return;
      }

      const { originalEvent } = $event;
      originalEvent.preventDefault();
      originalEvent.stopImmediatePropagation();

      if (this.isFavoriteDailyRead({ dailyReadId: this.dailyRead.id })) {
        const defaultSelectedLists = this.getFavoriteListsForDailyRead(this.dailyRead);
        this.showSelectFavoriteListDialog({ action: 'update', defaultSelectedLists, dailyRead: this.dailyRead });
      }
      else {
        this.showSelectFavoriteListDialog({ action: 'add', defaultSelectedLists: null, dailyRead: this.dailyRead });
      }

      originalEvent.target.blur();
    },

    async handleSubscribeButtonClick($event) {
      if (!this.isAllAccess) {
        return;
      }

      const { originalEvent } = $event;
      originalEvent.preventDefault();
      originalEvent.stopImmediatePropagation();

      if (this.isSubscribedToDailyRead({ dailyReadId: this.dailyRead.id })) {
        try {
          this.unsubscribeToDailyRead(this.dailyRead.id);
          this.$toast.open({
            message: `You will no longer receive ${this.dailyRead.name} Daily Snow posts via email.`,
            type: 'warning',
          });
        }
        catch (e) {
          this.subscriptionError();
        }
      }
      else {
        try {
          this.subscribeToDailyRead(this.dailyRead.id);
          this.$toast.open({
            message: `You will receive all ${this.dailyRead.name} Daily Snow posts via email.`,
            type: 'success',
          });
        }
        catch (e) {
          this.subscriptionError();
        }
      }

      originalEvent.target.blur();
    },

    subscriptionError() {
      this.$toast.open({
        message: `There was an error updating your ${this.dailyRead.name} Daily Snow subscription.`,
        type: 'error',
      });
    },
  },
};
</script>
